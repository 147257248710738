import React from 'react';
import './App.css';
import Grid from './components/Grid';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Game of Life in React</h1>
      </header>
      <main>
        <Grid />
      </main>
      <footer>
        <em>By <a href="https://pierrekarampournis.website">Pierre Karampournis</a></em>
        <p>Source code: <a href="https://gitlab.com/pkarampournis/game-of-life">https://gitlab.com/pkarampournis/game-of-life</a></p>
      </footer>
    </div>
  );
}

export default App;
