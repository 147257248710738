export const getNumberLivingNeighbors = (grid, i, j) => {
    const gridSize = grid.length;
    let top, topLeft, topRight, bottom, bottomLeft, bottomRight, left, right = 0;
    top = i > 0 && grid[i - 1][j];
    topLeft = i > 0 && j > 0 && grid[i - 1][j - 1];
    topRight = i > 0 && j < gridSize - 1 && grid[i - 1][j + 1];
    bottom = i < gridSize - 1 && grid[i + 1][j];
    bottomLeft = i < gridSize - 1 && j > 0 && grid[i + 1][j - 1];
    bottomRight = i < gridSize - 1 && j < gridSize - 1 && grid[i + 1][j + 1];
    left = j > 0 && grid[i][j - 1];
    right = j < gridSize - 1 && grid[i][j + 1];

    return +top + bottom + left + right + topLeft + topRight + bottomLeft + bottomRight;
};
const computeCellTargetState = (grid, i, j) => {
    const numberLivingNeighbors = getNumberLivingNeighbors(grid, i, j);

    //Rule 1: Any live cell with two or three live neighbours survives
    if (grid[i][j] && numberLivingNeighbors >= 2 && numberLivingNeighbors <= 3) {
        return true;
    }

    //Rule 2: Any dead cell with three live neighbours becomes a live cell
    if (!grid[i][j] && numberLivingNeighbors === 3) {
        return true;
    }

    //Rule3: All other live cells die in the next generation. Similarly, all other dead cells stay dead
    return false;
};

export default computeCellTargetState;