import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import Row from './Row';
import './Grid.css';
import Slider from './Slider';
import Controls from './Controls';
import computeCellTargetState from '../utils/gameOfLifeRules';

const getNewGrid = newGridSize => Array(newGridSize).fill().map(() => Array(newGridSize).fill(false));
const maxTimeout = 100000000;

function Grid() {


    const [gridSize, setGridSize] = useState(9);
    const initialGrid = getNewGrid(gridSize);
    const [petridish, setPetridish] = useState(initialGrid);
    const [generation, setGeneration] = useState(0);
    const [timerInterval, setTimerInterval] = useState(maxTimeout); //Hack: setting initial timeout to 100+ days to effectively 'Pause' the game

    const resetGame = () => setPetridish(initialGrid);

    const resizeGrid = newGridSize => {
        setGridSize(newGridSize);
        const newGrid = getNewGrid(newGridSize);
        setPetridish(newGrid);
    };

    const triggerNextGeneration = () => {
        setGeneration(generation + 1);
    }

    const toggleCellState = (x, y) => {
        // Clone petridish array
        const newPetridishState = petridish.map(row => row.slice());
        newPetridishState[x][y] = !newPetridishState[x][y];
        setPetridish(newPetridishState);
    };

    const computeNextGeneration = () => {
        const nextGeneration = getNewGrid(gridSize);

        //Apply Game of life rules to every cells
        for (let i = 0; i < gridSize; i++) {
            for (let j = 0; j < gridSize; j++) {
                nextGeneration[i][j] = computeCellTargetState(petridish, i, j);
            }
        }

        setPetridish(nextGeneration);
    };

    useEffect(() => {
        computeNextGeneration();
        const timer = setTimeout(triggerNextGeneration, timerInterval);
        return () => (clearTimeout(timer));
    }, [generation, timerInterval])

    return (
        <>
            <div className="gridContainer">
                <div className="grid">
                    {petridish.map((row, rowId) => <Row key={rowId} rowId={rowId} data={row} toggleCellState={toggleCellState} />)}
                </div>
            </div>
            <Slider setTimerInterval={setTimerInterval} />
            <Controls reset={resetGame} gridSize={gridSize} setGridSize={resizeGrid} />
        </>
    )
}

Grid.propTypes = {

}

export default Grid;

