import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './Slider.css';

function Slider(props) {
    const maxTimeout = 100000000;
    const timescale = [100, 500, 1000, 2000, 4000, 8000, maxTimeout];

    const [sliderPosition, setSliderPosition] = useState(timescale.length - 1);
    const handleSlide = (event) => {
        const position = event.target.value;
        setSliderPosition(position);
        props.setTimerInterval(timescale[position]);
    };

    return (
        <div className="slidecontainer">
            <h4>Game speed</h4>
            <input
                type="range"
                min="0"
                max={timescale.length - 1}
                value={sliderPosition}
                onChange={handleSlide}
                className="slider"
                id="timescale"
                step="1" />
        </div>
    )
}

Slider.propTypes = {

}

export default Slider

