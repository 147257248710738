import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './Controls.css';

function Controls(props) {

    const [gridSize, setGridSize] = useState(props.gridSize);

    const handleGridSizeChange = event => {
        event.preventDefault();
        props.setGridSize(gridSize);
    }

    return (
        <div className="controls">
            <div>
                <button onClick={props.reset}>Reset</button>
            </div>
            <div>
                <form onSubmit={handleGridSizeChange}>
                    <label htmlFor="gridSize">Grid size: </label>
                    <input
                        type="number"
                        name="gridSize"
                        id="gridSize"
                        value={gridSize}
                        onChange={(event) => setGridSize(+event.target.value)} />
                </form>
            </div>
        </div>
    )
}

Controls.propTypes = {

}

export default Controls

