import React from 'react'
import PropTypes from 'prop-types'
import Cell from './Cell'

function Row(props) {
    return (
        <div className="row">
            {[props.data.map((cell, cellId) => <Cell
                key={props.rowId + ',' + cellId}
                row={props.rowId}
                column={cellId}
                cellState={cell}
                toggleCellState={props.toggleCellState} />)]}
        </div>
    )
}

Row.propTypes = {

}

export default Row

