import React from 'react'
import PropTypes from 'prop-types'

function Cell(props) {

    const handleMouseOver = event => {
        if (event.buttons === 1) {
            props.toggleCellState(props.row, props.column);
        }
    };

    return (
        <div
            style={{ 'backgroundColor': props.cellState ? '#333' : '#eee' }}
            className="cell"
            onMouseDown={handleMouseOver}
            onMouseOver={handleMouseOver}>

        </div>
    )
}

Cell.propTypes = {

}

export default Cell

